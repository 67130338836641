import React, { useEffect, useState } from "react";
import { getAllEmployees } from "../axios/Employees";
import { createInfrequentScreening } from "../axios/Screenings";
import "./AddUserModal.css";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "60%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
};

const infrequentScreeningReasons = [
  { screeningReason: "Pre-Employment" },
  { screeningReason: "Post-Accident" },
  { screeningReason: "Follow-Up" },
  { screeningReason: "Return-To-Duty" },
  { screeningReason: "Reasonable-Suspicion" },
  { screeningReason: "Random" },
];

const emptyInfrequentScreening = {
  screeningType: "",
  screeningReason: "",
  screeningDate: "",
  selectedEmployee: {
    employeeId: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyId: "",
    status: "",
    failureReason: "",
  },
};

const failureReasons = [
  "Amphetamine",
  "Barbiturate",
  "Benzodiazepine",
  "Cannabis",
  "Cocaine",
  "Opioid",
];

export default function AddInfrequentScreeningModal({
  isOpen,
  handleClose,
  handleAlert,
}) {
  const [screeningDetails, setScreeningDetails] = useState(
    emptyInfrequentScreening
  );
  const [employeeMap, setEmployeeMap] = useState(new Map());

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "selectedEmployee") {
      const selectedEmployee = employeeMap.get(value);
      setScreeningDetails((prevState) => ({
        ...prevState,
        selectedEmployee,
      }));
    } else if (name === "status" || name === "failureReason") {
      setScreeningDetails((prevState) => ({
        ...prevState,
        selectedEmployee: {
          ...prevState.selectedEmployee,
          [name]: value,
        },
      }));
    } else {
      setScreeningDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (e) => {
    setScreeningDetails((prevState) => ({
      ...prevState,
      screeningDate: e.$d,
    }));
  };

  const handleCancelOrSave = async (e) => {
    e.preventDefault();
    if (e.target.name === "cancel") {
      handleClose();
      setScreeningDetails(emptyInfrequentScreening);
    } else {
      try {
        await createInfrequentScreening(screeningDetails);
        handleClose();
        setScreeningDetails(emptyInfrequentScreening);
        handleAlert("Infrequent screening added successfully!");
      } catch (error) {
        console.error("Error creating infrequent screening:", error);
        handleAlert(
          "Error adding infrequent screening. Please try again.",
          "error"
        );
      }
    }
  };

  const fetchEmployeesAndSave = async () => {
    try {
      const employees = await getAllEmployees();
      const employeeMap = new Map(
        employees
          .filter(
            (employee) => !employee.isHidden && employee.isHidden !== "true"
          )
          .map((employee) => [
            employee._id,
            {
              employeeId: employee._id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              companyName: employee.companyName,
              companyId: employee.companyId,
            },
          ])
      );
      setEmployeeMap(employeeMap);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployeesAndSave();
  }, []);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ClearIcon />
          </IconButton>
          <h3>Add A New Infrequent Screening</h3>
          <div className="inputContainer">
            <div className="formRow">
              <div className="formCell">Employee/Company</div>
              <Autocomplete
                size="small"
                fullWidth
                options={Array.from(employeeMap.values())}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName} - ${option.companyName}`
                }
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "selectedEmployee",
                      value: newValue ? newValue.employeeId : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={textFieldStyle} />
                )}
              />
            </div>

            <div className="formRow">
              <div className="formCell">Drug or Alcohol</div>
              <Autocomplete
                size="small"
                fullWidth
                options={["Drug", "Alcohol"]}
                onChange={(event, newValue) => {
                  handleChange({
                    target: { name: "screeningType", value: newValue },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={textFieldStyle} />
                )}
              />
            </div>

            <div className="formRow">
              <div className="formCell">Screening Reason</div>
              <Autocomplete
                size="small"
                fullWidth
                options={infrequentScreeningReasons.map(
                  ({ screeningReason }) => screeningReason
                )}
                onChange={(event, newValue) => {
                  handleChange({
                    target: { name: "screeningReason", value: newValue },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={textFieldStyle} />
                )}
              />
            </div>

            <div className="formRow">
              <div className="formCell">Screening Date</div>
              <LocalizationProvider
                className="localProvider"
                dateAdapter={AdapterDayjs}
              >
                <div className="customDatePickerWidth">
                  <DatePicker
                    textField={(params) => <TextField {...params} fullWidth />}
                    sx={{ width: "fullWidth" }}
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    onChange={handleDateChange}
                  />
                </div>
              </LocalizationProvider>
            </div>

            <div className="formRow">
              <div className="formCell">Status</div>
              <Autocomplete
                size="small"
                fullWidth
                options={[
                  "selected",
                  "passed",
                  "failed",
                  "wrong test",
                  "refused",
                  "not at company",
                  "sick",
                ]}
                onChange={(event, newValue) => {
                  handleChange({
                    target: { name: "status", value: newValue },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={textFieldStyle} />
                )}
              />
            </div>
            {screeningDetails.screeningType === "Drug" &&
              screeningDetails.selectedEmployee.status === "failed" && (
                <div className="formRow">
                  <div className="formCell">Failure Reason</div>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={failureReasons}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: { name: "failureReason", value: newValue },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={textFieldStyle} />
                    )}
                  />
                </div>
              )}
          </div>

          <div id="saveOrCancel">
            <SaveOrCancel
              onClick={handleCancelOrSave}
              name="cancel"
              variant="contained"
            >
              Cancel
            </SaveOrCancel>
            <SaveOrCancel
              onClick={handleCancelOrSave}
              name="save"
              variant="contained"
            >
              Save
            </SaveOrCancel>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
